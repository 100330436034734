<template>
    <div>
        <PageHeader :title="'Visualizar Contratos Ccee'" :items="breadcrumb" />

        <b-card class="mb-3 mt-3">
            <b-container fluid class="ml-3 mt-2">
                <b-row align-v="center" class="my-3">
                    <b-col md="2">
                        <b-form-group label="Mês de Referência" label-for="filtroMesRef">
                            <b-form-select id="filtroMesRef" v-model="filtroMesRef" :options="meses"
                                @change="atualizarDataRef" />
                        </b-form-group>
                    </b-col>

                    <b-col md="2">
                        <b-form-group label="Ano de Referência" label-for="filtroAnoRef">
                            <b-form-input id="filtroAnoRef" type="number" v-model="filtroAnoRef"
                                @change="atualizarDataRef" />
                        </b-form-group>
                    </b-col>

                    <b-col md="2">
                        <b-form-group label="Cliqccee" label-for="cliqccee" class="form">
                            <b-form-input id="cliqccee" type="text" v-model="cliqccee" @keyup.enter="fetchData"/>
                        </b-form-group>
                    </b-col>

                    <b-col md="2">
                        <b-form-group label="Fonte" label-for="fonte" class="form">
                            <b-form-select id="fonte" :options="fontes" v-model="fonte" />
                        </b-form-group>
                    </b-col>

                    <b-col md="2">
                        <b-form-group label="Submercado" label-for="submercado" class="form">
                            <b-form-select id="submercado" :options="submercados" v-model="submercado" />
                        </b-form-group>
                    </b-col>

                    <b-col md="2">
                        <b-form-group label="Vendedor" label-for="vendedor" class="form">
                            <b-form-input id="vendedor" type="text" v-model="vendedor" @keyup.enter="fetchData"/>
                        </b-form-group>
                    </b-col>

                    <b-col md="2">
                        <b-form-group label="Comprador" label-for="comprador" class="form">
                            <b-form-input id="comprador" type="text" v-model="comprador" @keyup.enter="fetchData"/>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row align-v="center" class="my-3">
                    <b-col md="2" class="d-flex justify-content">
                        <b-button @click="fetchData" class="ml-2">Listar Contratos</b-button>
                    </b-col>

                    <b-col md="2" class="d-flex justify-content">
                        <b-button @click="atualizarRef" class="ml-2">Atualizar Referência</b-button>
                    </b-col>

                    <b-col md="2" class="d-flex justify-content">
                        <b-button @click="validarRegistrosPendentes" class="ml-2">Validar Registros Pendentes</b-button>
                    </b-col>
                </b-row>
            </b-container>
        </b-card>

        <b-spinner v-if="loading" text-align="center" variant="success" label="Spinning"
                            class="ml-2 mt-3" />
        <ContratoCceeTable :loading="loading" :dados="dados"></ContratoCceeTable>
    </div>
</template>

<script>
import Vue from 'vue';
import VueMask from 'v-mask';
import PageHeader from '@/components/page-header';
import { findByMesRef, atualizarRef, validarRegistrosPendentes } from './ParcelaContratoCceeView.service';
import meses from '@/utils/meses';
import ContratoCceeTable from './ContratoCceeTable';

Vue.use(VueMask);

export default {
    data() {
        return {
            mesRefInput: '',
            dados: [],
            dadosMes: null,
            meses: meses(),
            mesRef: '',
            filtroMesRef: new Date().getMonth() + 1,
            filtroAnoRef: new Date().getFullYear(),
            loading: false,
            breadcrumb: [
                {
                    text: 'Backoffice',
                    href: '#'
                },
                {
                    text: 'Registros',
                    href: '#'
                },
                {
                    text: 'Contratos',
                    active: true
                }
            ],
            fontes: [
                {value: null, text: 'Todos'},
                { value: 'CO', text: 'CO' },
                { value: 'I0', text: 'I0' },
                { value: 'I5', text: 'I5' },
                { value: 'I1', text: 'I1' },
                { value: 'INE', text: 'INE' },
                { value: 'CQ5', text: 'CQ5' }],
            fonte: null,
            submercados: [
                {value: null, text: 'Todos'},
                { value: 'SE', text: 'SE' },
                { value: 'S', text: 'S' },
                { value: 'N', text: 'N' },
                { value: 'NE', text: 'NE' }],
            submercado: null,
            cliqccee: null,
            vendedor: null,
            comprador: null
      }
    },
    components: {
        PageHeader, ContratoCceeTable
    },
    created() {
        this.atualizarDataRef();
    },
    methods: {
        atualizarDataRef() {
            if (this.filtroMesRef && this.filtroAnoRef) {
                const mesIndexZeroBase = this.filtroMesRef - 1;
                this.mesRefInput = `${this.filtroAnoRef}-${String(
                    mesIndexZeroBase + 1
                ).padStart(2, '0')}-01`;
            }
        },
        getFiltro(){
            return {
                cliqccee: this.cliqccee,
                fonte: this.fonte,
                submercado: this.submercado,
                vendedor: this.vendedor,
                comprador: this.comprador
            };
        },
        fetchData() {
            this.loading = true;

            findByMesRef(this.mesRefInput, this.getFiltro())
                .then((response) => {
                    if (response.status && response.content) {
                        this.dados = response.content;
                    } else {
                        console.error(
                            'Resposta do servidor não está no formato esperado:',
                            response
                        );
                    }
                })
                .catch((error) => {
                    console.error('Erro ao buscar dados:', error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        atualizarRef() {
            this.loading = true;

            atualizarRef(this.mesRefInput)
            .then((response) => {
                    if (response.status == 200) {
                        this.fetchData();
                    } else {
                        console.error(
                            'Resposta do servidor não está no formato esperado:',
                            response
                        );
                    }
                })
            .catch((error) => {
                console.error('Erro ao buscar dados:', error);
            })
            .finally(() => {
                this.loading = false;
            });
        },
        validarRegistrosPendentes(){
            this.loading = true;
            
            validarRegistrosPendentes(this.mesRefInput)
                .then((response) => {

                })
                .catch((error) => {
                    console.error('Erro ao buscar dados:', error);
                }).finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>